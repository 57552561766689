import { useEffect } from 'react';
import { useFetch } from './hooks/UseFetch';

function App() {
  // Get the project ID from the URL query parameters
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('project');

  const { data, err } = useFetch(`frontnd-mini-projects/${id}/`);

  useEffect(() => {
    if (data && data.code) {
      // Inject styles into the head
      const styleRegex = /<style[^>]*>([\s\S]*?)<\/style>/gi;
      let styleMatch;
      while ((styleMatch = styleRegex.exec(data.code)) !== null) {
        const styleTag = document.createElement('style');
        styleTag.innerHTML = styleMatch[1];
        document.head.appendChild(styleTag);
      }

      // Inject and run scripts
      const scriptRegex = /<script[^>]*>([\s\S]*?)<\/script>/gi;
      let scriptMatch;
      while ((scriptMatch = scriptRegex.exec(data.code)) !== null) {
        const scriptContent = scriptMatch[1];
        const scriptTag = document.createElement('script');

        // Use an IIFE to prevent variable redeclaration issues
        scriptTag.innerHTML = `(() => { ${scriptContent} })();`;

        document.body.appendChild(scriptTag);
      }
    }
  }, [data]);

  // Friendly error styles defined as a JavaScript object
  const errorStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#e7f1ff',
    color: '#31708f',
    border: '1px solid #bce8f1',
    borderRadius: '5px',
    padding: '20px',
    textAlign: 'center',
    fontSize: '24px',
    fontFamily: 'Arial, sans-serif',
  };

  const linkStyles = {
    marginTop: '15px',
    color: '#31708f',
    textDecoration: 'underline',
  };

  return (
    <>
      {data && err === null ? (
        <div
          dangerouslySetInnerHTML={{
            __html: data.code ? data.code.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '') : '',
          }}
        />
      ) : (
        err && (
          <div style={errorStyles}>
            Oops! We couldn't find the project you're looking for. Please check the link or try again later!
            <a href="https://nasirahmadahmady.com" rel="noopener noreferrer" style={linkStyles}>
              Visit our main website
            </a>
          </div>
        )
      )}
    </>
  );
}

export default App;
